<template>
	<div class="change-password">
		<form class="change-password-form" @submit.prevent="changePassword">
			<div class="input-field">
				<input
					type="password"
					v-model="currentPassword"
					placeholder="Current password"
					@keypress="error = null; success = null"
					:disabled="isLoading" required />
			</div>
			<div class="input-field new-password">
				<input
					:type="inputType"
					v-model="newPassword"
					placeholder="New passowrd"
					@keypress="error = null; success = null"
					:disabled="isLoading" required />
				<div @click="changeType" class="show-password">
					<i v-if="inputType != 'password'" class="fas fa-eye"></i>
					<i v-else class="fas fa-eye-slash"></i>
				</div>
			</div>
			<button class="btn btn-primary changepass-submit" type="submit">
				<div v-if="!isLoading">Change Password</div>
				<div v-else class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</button>
		</form>
	</div>
</template>

<script>
import firebase from 'firebase/compat'
require('firebase/auth')

export default {
	name: 'AdminChangePassword',
	data() {
		return {
			inputType: 'password',
			isLoading: false,
			currentPassword: '',
			newPassword: '',
		} 
	},
	methods: {
		reauthenticate(currentPassword) {
			var user = firebase.auth().currentUser;
			let cred = firebase.auth.EmailAuthProvider.credential('rtm909@gmail.com', currentPassword);
			return user.reauthenticateWithCredential(cred);
		},
		changeType() {
			this.inputType = 'password' == this.inputType ? 'text' : 'password';
		},
		async changePassword() {
			this.isLoading = true;
			try {
				await this.reauthenticate(this.currentPassword).then(() => {
					let user = firebase.auth().currentUser;
					user.updatePassword(this.newPassword).then(() => {
						this.currentPassword = '';
						this.newPassword = '';
						this.$emit('notification', 'Password updated successfully!');
					})
				}).catch((err) => {
					this.currentPassword = '';
					this.$emit('notification', err.message.slice(10), 'error');
				})
			} catch (err) {
				console.log('here ' + err)
				this.$emit('notification', err.message.slice(10), 'error');
			}
			this.isLoading = false;
		}
	}
}
</script>

<style scoped>
.change-password-form {
	padding: 5rem 2rem;
	display: grid;
	grid-row-gap: 2rem;
	justify-content: center;
}

.new-password {
	display: flex;
	grid-gap: 1rem;
	align-items: center;
}
.fas {
	padding: 0.76rem;
	background-color: wheat;
	color: black;
	cursor: pointer;
	border-radius: 8px;
}
.fas:hover {
	background-color: #9b8c70;
}

.changepass-submit {
	width: 9rem;
	margin: auto;
}

input[type=text], input[type=password] {
	border-radius: 8px;
	padding: 8px;
	vertical-align: middle;
	border: none;
	color: black;
	background: #f1f1f1;
	opacity: 0.9;
	text-align: left;
	min-width: 26%;
	width: 100%;
}
input[type=text]:focus, input[type=password]:focus {
	background-color: #ddd;
	outline: none;
}

/* Loading spinner */
.lds-ring {
	display: inline-flex;
	position: relative;
	width: 48px;
	transform: scale(0.4);
	bottom: 6px;
	align-items: center;
	justify-content: center;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>